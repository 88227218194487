<template>
  <div v-if="item" class="cart-modal-wrapper">
    <div class="cart-modal" data-testid="cart-confirmation-modal">
      <div class="cart-modal-btn-wrapper">
        <button class="cart-modal-close" @click="close()" />
      </div>
      <div class="cart-modal-content">
        <CartStockPreview :stock="item.stock" :cart-quantity="item.quantity" mode="LIGHT" />
        <MainButton
          :label="$t('cart.seeCart')"
          :link="localePath('cart')"
          class-variant="full-width"
          @click="close()"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import MainButton from '@/components/Buttons/MainButton/MainButton'
import CartStockPreview from '@/components/Cart/CartStockPreview/CartStockPreview'

const emit = defineEmits(['modal-show', 'modal-hide'])
const item = ref(null)
const timeout = ref(null)
const { $bus } = useNuxtApp()

onMounted(() => {
  $bus.on('confirmCartAdded', cartItem => {
    // Cancel previous hide timeout
    if (timeout.value !== null) {
      clearTimeout(timeout.value)
      close()
    }
    item.value = cartItem
    emit('modal-show')
    timeout.value = setTimeout(close, 5000)
  })
})

onBeforeUnmount(() => {
  $bus.off('confirmCartAdded')
  if (timeout.value !== null) {
    clearTimeout(timeout.value)
  }
})

const close = () => {
  if (timeout.value !== null) {
    clearTimeout(timeout.value)
  }
  item.value = null
  timeout.value = null
  emit('modal-hide')
}
</script>
