<template>
  <div v-if="stock" class="cart-stock-preview">
    <div class="cart-stock-preview-main">
      <div v-if="reference" class="img-wrapper">
        <ImageLazy :src="reference.thumbnailUrl" :alt="reference.name" :title="reference.name" />
      </div>
      <div v-if="reference && reference.hasBrand" class="product-brand">
        <ImageLazy
          v-if="reference.hasBrandLogo"
          :src="reference.brand.thumbnail || reference.brand.logo_url"
          :alt="reference.brand.name"
          :title="reference.brand.name"
        />
        <span v-else>{{ reference.brand.name }}</span>
      </div>
      <div class="txt-wrapper">
        <div class="product-ref-wrapper">
          <RefcoLabel :refco="refCoValue" class="no-label" />
        </div>
        <NuxtLinkLocale
          v-if="reference"
          :to="{
            name: 'product-slug',
            params: { slug: reference.slug },
          }"
          class="product-title"
        >
          {{ reference.name }}
        </NuxtLinkLocale>
        <div class="product-info-wrapper">
          <div class="product-price-wrapper">
            <div class="unit-price">
              <span v-if="catalogPriceEquivalent > 0 && stock.percentageOff() < 0" class="price-catalog">
                {{ localePrice(catalogPriceEquivalent) }}
              </span>
              <span class="price-unit">
                {{ localePrice(stock.storePrice(showVAT)) }}&nbsp;{{ $t('product.theUnit') }}
              </span>
            </div>
            <div class="calc-price">
              <span class="price-value">
                {{ localePrice(stock.storePrice(showVAT) * cartQuantity) }}
              </span>
              <div class="price-details">
                <span v-if="stock.isBatch" class="batch">
                  {{ $t(stock.packagingLabel) }}
                </span>
                <span class="vat">{{ showVAT ? $t('actions.VAT') : $t('actions.noVAT') }}</span>
              </div>
            </div>
            <p v-if="stock.savings(showVAT) > 0" class="price-economy">
              {{
                $t('product.ofSavings', {
                  amount: localePrice(stock.savings(showVAT) * cartQuantity),
                })
              }}
            </p>
          </div>
          <span v-if="stock.reference.accreditation" class="icon-problem-grapefruit" />
        </div>
        <expiry-date-label :stock="stock" class-variant-label="sm" class-variant-value="sm" />
        <div v-if="mode !== 'CART' && cartQuantity > 0" class="product-quantity-wrapper">
          <p v-if="stock.isBatch">
            {{ $t('product.addedQuantityBatch', stock.quantity) }}
          </p>
          <p v-else>
            {{ $t('product.addedQuantity', cartQuantity) }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useReference } from '@/composables/useReference'

import Price from '@/mixins/price-mixin'
import Club from '@/mixins/club-mixin'
import i18nMx from '@/mixins/i18n-mixin'

import ExpiryDateLabel from '@/components/ExpiryDateLabel/ExpiryDateLabel'
import RefcoLabel from '@/components/RefcoLabel/RefcoLabel'

export default {
  name: 'CartStockPreview',
  components: {
    ExpiryDateLabel,
    RefcoLabel,
  },
  mixins: [Price, Club, i18nMx],
  props: {
    stock: { type: Object, default: null },
    cartQuantity: { type: Number, default: 0 },
    showDistance: { type: Boolean, default: true },
    mode: { type: String, default: 'CART' }, // CART/LIGHT/PURCHASE/SALE
  },
  computed: {
    reference() {
      if (this.stock.reference.refCo !== null && this.stock.reference.refCo !== undefined) {
        return this.stock.reference
      } else if (this.stock.reference) {
        return useReference(this.stock.reference)
      }
      return null
    },
    refCoValue() {
      if (this.reference.refCo) {
        return this.reference.refCo
      }
      return this.reference.refCo
    },
    isAPurchase() {
      return this.mode === 'PURCHASE'
    },
    catalogPriceEquivalent() {
      return this.stock?.catalogPriceEquivalent(this.showVAT, this)
    },
  },
}
</script>

<style lang="scss">
.cart-stock-preview {
  &-main {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin: -10px;

    .product-ref-wrapper {
      display: flex;
      margin-bottom: $spacing-sm;
    }

    .img-wrapper,
    .txt-wrapper {
      padding: 10px;
    }

    .img-wrapper {
      img {
        width: 100px;
        height: 100px;
        object-fit: contain;

        @include mq($mq-sm) {
          width: 60px;
          height: 60px;
        }
      }
    }

    .txt-wrapper {
      .product-title {
        margin-top: 0;
        transition:
          color var(--transition-duration) var(--transition-timing-function),
          transform var(--transition-duration) var(--transition-timing-function);

        &:hover {
          color: var(--twilight-blue);
          transform: translate(2px, 0);
        }
      }
      .product-info-wrapper {
        display: flex;
        align-items: flex-end;
        .product-price-wrapper {
          flex-grow: 1;
          font-weight: 800;

          .unit-price {
            font-size: pxToRem(12px);
            margin: 5px 0;

            .price-catalog {
              display: inline-block;
              margin-right: 5px;
              color: var(--grapefruit);
              text-decoration: line-through;
            }

            .price-unit {
              color: var(--steel-grey);
            }
          }

          .calc-price {
            display: flex;
            align-items: flex-end;
            line-height: 1;
            margin: 5px 0;

            .price-value {
              display: inline-block;
              margin-right: 10px;
              font-size: pxToRem(24px);
            }

            .price-details {
              .batch {
                display: block;
                font-weight: 500;
                font-size: pxToRem(10px);
              }

              .vat {
                display: block;
                font-size: pxToRem(13px);
              }
            }
          }

          .price-economy {
            color: var(--white);
            background-color: var(--twilight-blue);
            font-weight: 700;
            font-size: pxToRem(12px);
            display: inline-block;
            padding: 2px 5px;
            border-radius: 5px;
            margin: 5px 0;
          }

          .product-expiry-date {
            margin: 5px 0;
          }
        }
        .icon-problem-grapefruit {
          width: 30px;
          height: 30px;
          mask-image: url('@/assets/icons/ico-problem.svg');
          background-color: var(--grapefruit);
        }
      }
    }

    .product-quantity-wrapper {
      font-weight: 800;

      p {
        color: var(--steel-grey);
        font-size: pxToRem(12px);
        margin: 5px 0;
      }
    }
    .product-brand {
      border-radius: 0 $spacing-sm;
      position: absolute;
      left: 1px;
      bottom: 1px;
      padding: 5px;
      background-color: var(--white);

      img {
        display: block;
        max-width: 65px;
        max-height: 50px;
        width: 65px;
        height: 50px;
        width: auto;
        height: auto;
      }

      span {
        color: var(--night-blue);
        text-transform: uppercase;
        font-size: pxToRem(14px);
        font-weight: 800;
      }
    }
  }
}
</style>
